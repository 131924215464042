<template>
    <div class="comment-wrapper">
        <div class="comment">
            <div class="top-row">
                <div class="author">
                    {{ getCommentOwner }}
                </div>
                <div class="right-group">
                    <div class="date">
                        {{ $moment(comment.created_at).format("MMM DD, YYYY") }}
                    </div>
                    <button
                        v-if="allowUpdate"
                        class="context-btn"
                        @click.stop="toggleMenu"
                        :class="{ active: contextMenuOpened }">
                        <div>
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </div>
                        <div
                            class="context-menu"
                            v-if="contextMenuOpened"
                            v-on-clickaway="contextClickAway">
                            <div
                                class="item"
                                @click.self="
                                    startEdition(comment.id, comment.comment, comment.user_id)
                                ">
                                Edit
                            </div>
                            <div
                                class="item red-btn"
                                @click="deleteComment(comment.id, comment.user_id)">
                                Delete
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div class="content" v-if="!editionOpened">
                {{ comment.comment }}
            </div>
            <div class="content edit-wrapper" v-if="editionOpened">
                <input type="text" v-model="editableCommentText"/>
                <div class="edit-controls">
                    <button @click="cancelEdition" class="cancel-btn red-btn">
                        Cancel
                    </button>
                    <button class="save-btn" @click="saveEditedComment">Save</button>
                </div>
            </div>
        </div>
        <slot> </slot>
    </div>
</template>

<script>
    import { directive as onClickaway } from "vue-clickaway";
    import { mapGetters } from "vuex";

    export default {
        directives: {
            onClickaway: onClickaway
        },
        name: "comment",
        props: {
            comment: {
                type: Object,
                default: () => ({})
            },
            contextMenuOpened: {
                type: Boolean,
                default: false
            },
            allowUpdate: {
                type: Boolean,
                default: false
            },
            editionOpened: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            editableCommentText: null
        }),
        computed: {
            ...mapGetters([
                "isClient",
                "isDesigner"
            ]),
            getCommentOwner() {
                const commentOwner = this.comment.user || this.comment.user_name;
                const { client, designer } = this.$config.user.roles;
                if (this.isClient || this.isDesigner) {
                    if (this.isClient && this.comment.user_role === designer || this.isDesigner && this.comment.user_role === client) {
                        return commentOwner.split(" ")[0];
                    }
                    return commentOwner;
                }
                return commentOwner;
            }
        },
        methods: {
            toggleMenu() {
                this.$emit("openMenu");
            },
            contextClickAway() {
                this.$emit("closeMenu");
            },
            startEdition() {
                if (this.allowUpdate) {
                    this.$emit("startEdit", this.comment.id);
                    this.editableCommentText = this.comment.comment;
                }
            },
            deleteComment(id) {
                if (this.allowUpdate) {
                    this.$emit("deleteComment", id);
                }
            },
            saveEditedComment() {
                const payload = {
                    text: this.editableCommentText,
                    id: this.comment.id
                };
                this.$emit("updateComment", payload);
                this.editableCommentText = null;
            },
            cancelEdition() {
                this.$emit("cancelEdition");
                this.editableCommentText = null;
            },
            cancelReply() {
                this.replyFieldOpened = false;
            }
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"/>
