<template>
    <div
        class="comments-thread"
        @away="closeThread"
        @mouseover.capture.stop="$emit('hover')"
        @mouseleave.capture.stop="$emit('leave')">
        <div class="marker" ref="marker" @click.capture="expand">
            <div class="id">
                {{ id }}
            </div>
        </div>
        <div
            class="comments-container"
            v-if="expanded"
            :class="{
                'to-right': direction === 'right',
                'to-left': direction === 'left',
            }">
            <comment
                :comment="comment"
                :allow-update="profile.id === comment.user_id && allowEditComment"
                :context-menu-opened="contextOpenedForComment === comment.id"
                :edition-opened="editionActiveForComment === comment.id"
                @openMenu="contextOpenedForComment = comment.id"
                @closeMenu="contextOpenedForComment = null"
                @startEdit="editionActiveForComment=$event"
                @cancelEdition="editionActiveForComment=null"
                @updateComment="updateComment"
                @deleteComment="deleteComment">
                <div class="comments-replies" v-if="replies">
                    <comment
                        v-for="(reply, index) of replies"
                        :key="index"
                        :comment="reply"
                        :edition-opened="editionActiveForComment === reply.id"
                        :allow-update="profile.id === reply.user_id && allowEditComment"
                        :context-menu-opened="contextOpenedForComment === reply.id"
                        @openMenu="contextOpenedForComment = reply.id"
                        @closeMenu="contextOpenedForComment = null"
                        @startEdit="editionActiveForComment=$event"
                        @cancelEdition="editionActiveForComment=null"
                        @updateComment="updateComment"
                        @deleteComment="deleteComment"/>
                </div>
            </comment>

            <div class="reply-form" v-if="replyFieldOpened">
                <div class="symbols">{{ reply ? reply.length : 0 }}/500</div>
                <input
                    type="text"
                    placeholder="Add reply"
                    v-model="reply"
                    :maxlength="maxCommentLength"/>
            </div>
            <div class="controlls" v-if="!editingComment">
                <v-btn text small @click="closeThread">Close</v-btn>
                <template v-if="allowReplayComment">
                    <v-btn text
                           small
                           @click="addReply"
                           v-if="replyFieldOpened"
                           :loading="isReplyLoading.loading"
                           :disabled="!reply || isReplyLoading.loading"
                           :class="{ active: reply && reply.length > 0 }">
                        Save
                    </v-btn>
                    <v-btn text small @click="replyFieldOpened = true" v-if="!replyFieldOpened">
                        Reply
                    </v-btn>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from "vue-clickaway";
    import { mapGetters } from "vuex";
    import Comment from "@/components/Comment";

    export default {
        mixins: [clickaway],
        inject: ["isReplyLoading"],
        props: {
            id: Number,
            text: String,
            allowReplayComment: {
                type: Boolean,
                default: true
            },
            allowEditComment: {
                type: Boolean,
                default: true
            },
            position: {
                type: Object,
                default: () => ({
                    x: 0,
                    y: 0
                })
            },
            comment: {
                type: Object
            },
            expanded: {
                type: Boolean,
                default: false
            },
            replies: {
                type: Array,
                default: null
            },

            sceneWidth: {
                type: Number,
                default: 400
            },
            sceneHeight: {
                type: Number,
                default: 400
            }
        },
        components: {
            comment: Comment
        },
        data: () => ({
            replyFieldOpened: false,
            direction: null,
            reply: "",
            editingComment: null,
            maxCommentLength: 500,
            markerPosition: {
                x: 0,
                y: 0
            },

            contextOpenedForComment: null,
            editionActiveForComment: null
        }),
        watch: {
            "isReplyLoading.loading" (val)  {
                if(!val) {
                    this.reply = ""
                    this.replyFieldOpened = false;
                }
            }
        },
        methods: {
            expand() {
                this.$emit("expand");
                this.calculateDirection();
            },
            calculateDirection() {
                const commentWidth = 270;
                const canBeOpenedToRight =
                    this.position.x + commentWidth <= this.sceneWidth;
                const canBeOpenedToLeft = this.position.x - commentWidth >= 0;

                let vertical = "right";

                if (canBeOpenedToRight && !canBeOpenedToLeft) {
                    vertical = "right";
                } else {
                    vertical = "left";
                }

                this.direction = vertical;
            },
            closeThread() {
                this.$emit("close");
            },
            addReply() {
                if(!this.reply) return
                this.$emit("addReply", this.reply.trim());
            },
            updateComment(commentData){
                this.$emit("updateComment", commentData);
                this.editionActiveForComment=null
            },
            deleteComment(commentId){
                this.$emit("deleteComment", commentId)
            }
        },
        computed: {
            ...mapGetters(["profile"])
        }
    };
</script>

<style scoped lang="scss" src="./style.scss"></style>
